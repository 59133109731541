<template>
  <div class="zones">
    <h1>Sélectionnez la zone géographique de votre choix</h1>
    <div class="container">
      <router-link v-for="c in zones" :key="c.id" class="btn-primary" :to="{ name: 'Zone', params: {
          slug: c.strArea
        }
      }">
        {{ c.strArea }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Zones',
  data () {
    return {
      zones: null
    }
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/list.php?a=list')
      .then(response => response.json())
      .then(response => {
        this.zones = response.meals
      })
  }
}
</script>

<style scoped>
  .container {
    max-width: 1200px;
    margin: auto;
  }
  .btn-primary {
    display: inline-block;
    margin: 12px;
  }
</style>
